<template>
    <modal class="chexiaoPayModel" :isShow="isShow">
        <div class="header">撤销</div>
        <div class="modal-body">
            <div class="title">账户信息</div>
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">应收账户：</span>
                    <div class="from-input">{{data?.Corp_Name}}</div>
                </div>
                <div class="li-flex">
                    <div class="td-flex">
                        <span class="lable-text">付款方式：</span>
                        <div class="from-input">{{data?.Pay_Name}}</div>
                    </div>
                    <div class="td-flex">
                        <span class="lable-text">转账金额：</span>
                        <div class="from-input">
                            <span class="money">￥{{data?.Pay_Money}}</span>
                        </div>
                    </div>
                </div>
                <div class="li-flex">
                    <div class="td-flex">
                        <span class="lable-text">操作人：</span>
                        <div class="from-input">{{data?.Corp_Czy}}</div>
                    </div>
                    <div class="td-flex">
                        <span class="lable-text">操作时间：</span>
                        <div class="from-input">{{dateFormat(null,null,data?.Corp_Time)}}</div>
                    </div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">备注：</span>
                    <div class="from-input">{{data?.Remark}}</div>
                </div>
            </div>
            <div class="title">挂账单据</div>
            <div class="table-box">
                <el-table class="el-table--scrollable-y" height="260" style="width: 100%;" 
                    ref="tableEl"
                    border
                    v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                    show-summary
                    :summary-method="getSummaries"
                    :data="tableData"
                >
                    <el-table-column prop="Rpt_Date" label="挂账日期" width="80"  :formatter="shortDateFormat" align="left"></el-table-column>
                    <el-table-column prop="VoucherNo" label="结账单号" min-width="120" align="left"></el-table-column>
                    <el-table-column prop="Corp_Money" label="挂账金额" width="70" align="right" data-format="number" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="Pay_Money" label="已结金额" width="70" align="right" data-format="number" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="ThisPayMoney" label="本次清账金额" width="90" align="right" data-format="number" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="Corp_Czy" label="操作人" width="80" align="left"></el-table-column> 
                    <el-table-column prop="Corp_Time" label="操作时间" width="130" :formatter="dateFormat" align="left"></el-table-column> 
                    <el-table-column prop="Remark" label="备注" min-width="100" align="left"></el-table-column> 
                </el-table>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()"> 确认</button>
        </div>
    </modal>
</template>

<script>

// 清账、预付款 撤销
export default {
    name:'chexiaoPayModel',
    emits:["close","confirm"],
    props:{
        isShow:Boolean,
        data:{
            typeof:Object,
            default:null
        }
    },
    data(){
        return{
            tableData:[]
        }
    },
    mounted(){
        this.loadData();
    },
    watch:{
        isShow(newValue){
            if(newValue){
               
                this.loadData();
            }
        }
    },
    methods:{
        hide(){
            this.$emit("close");
        },
        //加载数据
        loadData(){
            
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetARCorpMoneyConsumeInfo", {
                Corp_MoneyID:this.data?.Corp_MoneyID
            }).then((d)=>{
                console.log(d)
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.tableData=d.ResponseBody||[];
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("账户数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        getSummaries({ columns, data }){
            const sums= [];
            let total={Corp_Money:0,Pay_Money:0,ThisPayMoney:0};
            data.forEach(it=>{
                total.Corp_Money+=it.Corp_Money;
                total.Pay_Money+=it.Pay_Money;
                total.ThisPayMoney+=it.ThisPayMoney;
            })
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]= parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        /**点击确认 */
        confirm(){
            if(!this.data?.Corp_MoneyID){
                this.$message.warning("撤销明细不能为空!");
                return;
            }
            
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.RefundARCorpPay",{
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Pos_StationName:userInfo?.Site_Name,//站点名称
                Operate_EquipmentID:userInfo?.Site_Serial, //设备id
                Corp_MoneyID:this.data?.Corp_MoneyID,//支付信息ID
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("撤销成功");
                    this.$emit("confirm",d.ResponseBody?.Corp_LastUpdateTime);
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('撤销失败：'+e.message);
                console.log('撤销失败：',e);
            })
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
    }
}
</script>

<style lang="scss">
@import './chexiaoPayModel.scss'
</style>